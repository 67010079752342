$colors: (
  black: #000,
  dark: #111111,
  darkBlue: #3b5998,
  darkOverlay: rgba(0, 0, 0, 0.6),
  grey: #151515,
  lightBlue: #1da1f2,
  primary: #96281b,
  red: #ff0e1f,
  white: #ffffff,
);

$system-font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
  'Helvetica Neue', sans-serif;

$main-font: 'Palanquin', sans-serif;
$default-font-size: 1.6rem;
